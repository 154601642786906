module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Boarddeckhq","short_name":"Boarddeckhq","start_url":"/","background_color":"#fff","theme_color":"#121f51","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5f5d15a459b83a63f9d06b12cf9b01a8"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://boarddeckhq.com","stripQueryString":true},
    },{
      plugin: require('../../../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../../../packages/gatsby-theme-seomass/gatsby-browser.js'),
      options: {"plugins":[],"siteMetadata":{"siteTitle":"Boarddeckhq","siteDescription":"Compare the top e-Rides!","siteUrl":"https://boarddeckhq.com","siteSubject":"e-Rides","siteSubjectSlug":"e-rides","siteLanguage":"en","siteImage":"/banner.jpg","siteTheme":{"mainColor":"#121f51","secondColor":"#626b8c","thirdColor":"#313d68"},"siteNavigation":[{"name":"e-Rides","slug":"/e-rides/","submenu":"categories"},{"name":"Blog","slug":"/blog/"},{"name":"About","slug":"/about/","isCustomPage":true,"staticSubMenu":[{"name":"Contact us","slug":"/contact/"}]}]}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-windicss/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
